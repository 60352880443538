//import axios from "axios/index";
// import Vue from 'vue'
import i18n from '@/lang/i18n';

const state = {
    rules: {
        required: v => (v && v !== '') || i18n.t('rules.required'),
        notEmpty: v => (v && parseInt(v) > 0) || i18n.t('rules.not_empty'),
        latin: v => {
            const pattern = /^[a-z]+$/
            return pattern.test(v) || i18n.t('rules.latin_small_only')
        },
        latin2: v => {
            const pattern = /^[a-z_]+$/
            return pattern.test(v) || i18n.t('rules.latin_small_or_')
        },
        json: v => {

            if(!v){
                return true
            }

            try
            {
                let json = JSON.parse(v);
            }
            catch(e)
            {
                return 'invalid json'
            }
            return true
        },
        digits: v => {

            if(!v){
                return true
            }

            const pattern = /\d+/g
            return pattern.test(v) || 'Only digits'
        },
        onlyDigits: v => {
            if(!v){
                return true
            }

            const pattern = /^\d+$/
            return pattern.test(v) || i18n.t('rules.onlyDigits')
        },
        minLimit: (v) => v > 0 || i18n.t("rules.minLimit"),
        floats: v => {
            if(!v){
                return true
            }

            const pattern = /^[0-9.,]+$/
            return pattern.test(v) || i18n.t('rules.onlyDigits')
        },
        phone: v => {
            const pattern = /^\+380[0-9]{9}$/
            return pattern.test(v) || i18n.t('rules.phone')
        },
        birthday: v => {
            const pattern = /^([0-2][0-9]|(3)[0-1])(\.)(((0)[0-9])|((1)[0-2]))(\.)\d{4}$/i;
            return pattern.test(v) || i18n.t('rules.birthday');
        },
        email: v => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(v) || i18n.t('rules.email')
        },
        stringMaxLength: v => v?.length <= 255 || i18n.t('rules.stringMaxLength'),
				latinDigits: v => {
					// const pattern = /^[A-Za-z0-9 {}]+$/
					const pattern = /^[A-Za-z0-9 :_]+$/;
					return pattern.test(v) || i18n.t('rules.latin_and_digits')
				},
    },

     ajax_dialog: {
        show: false,
        loading: false,
        title: "",
        description: "",
			 	error: false,
			 	options: null,
			 	message_type: null,
			 	message_data: null,
    },

    dataLoaded: false,

	passwordSchemaData: ["min","uppercase","lowercase","digits","symbols", "spaces"],

	notifications: [],

}

const actions  = {

    //запуск диалога
    updateAjaxDialog(
			{commit},
			[
				show,
				loading,
				title,
				description,
				error = false,
				options = null,
				message_type = null,
				message_data = null,
			]
		) {
        commit(
					'UPDATE_AJAX_DIALOG',
					[
						show,
						loading,
						title,
						description,
						error,
						options,
						message_type,
						message_data,
					]
				);
    },

    //запуск диалога
    setDataLoaded({commit}, flag) {
        commit('SET_DATA_LOADED', flag);
    },

    //запуск диалога с обработкой ошибки
    updateAjaxDialogError({commit}, [err, title = '', description = '']) {
        
        if(title === ''){
            //title = i18n.t('error');
        }

        if(err && err.response) {
            if (err.response.status === 401) {
                //description = i18n.t('error.permission_denied');
            }

            if (err.response.data) {

                if (err.response.data.message_header) {
                    title = err.response.data.message_header;
                    // if (i18n.t(err.response.data.message_header)) {
                    //     title = i18n.t(err.response.data.message_header);
                    // }
                }

                if (err.response.data.message_description) {
                    description = err.response.data.message_description;
                }
            }
        }

        commit('UPDATE_AJAX_DIALOG', [true, false, title, description, true]);

    },

	setNotifications({commit}, array) {
		commit('SET_NOTIFICATIONS', array)
	},
	setNotification({commit}, payload) {
		commit('SET_NOTIFICATION', payload)
	},

}

const mutations = {

    SET_DATA_LOADED(state, flag) {
        state.dataLoaded = flag;
    },

    UPDATE_AJAX_DIALOG(
			state,
			[
				show,
				loading,
				title,
				description,
				error,
				options = null,
				message_type = null,
				message_data = null,
			]
		) {

        if (typeof show !== undefined) {
            state.ajax_dialog.show = show;
        }
        if (typeof loading !== undefined) {
            state.ajax_dialog.loading = loading;
        }
        if (typeof title !== undefined) {
            state.ajax_dialog.title = title;
        }
        if (typeof description !== undefined) {
            state.ajax_dialog.description = description;
        }

			state.ajax_dialog.error = error;
			state.ajax_dialog.options = options;
			state.ajax_dialog.message_type = message_type;
			state.ajax_dialog.message_data = message_data;

    },

	SET_NOTIFICATION(state, payload) {
		state.notifications.push(payload)
	},

	SET_NOTIFICATIONS(state, payload) {
		state.notifications = payload
	},

}


const getters = {

    dataLoaded: state => {
        return state.dataLoaded;
    },

     ajax_dialog: state => {
        return state.ajax_dialog;
    },

    rules: state => {
        return state.rules;
    },

		passwordSchemaData: state => {
        return state.passwordSchemaData;
    },

	getNotifications: (state) => {
		return state.notifications
	},

}

export default {
    state,
    mutations,
    actions,
    getters
}
